.fraud-alert {
  --fs-mob: 10px;
  color: var(--color-blue-1100);
  font-size: var(--fs-mob);
}
.fraud-alert__header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
  padding: var(--space-8);
  line-height: 1.35;
  background-color: var(--color-blue-200);
}
@media (min-width: 768px) {
  .fraud-alert__header {
    background-color: var(--color-brand-900);
    color: #fff;
    font-size: 0.88rem;
    gap: var(--space-16);
  }
  .fraud-alert__header .pipe {
    width: 1px;
    height: 26px;
    background-color: #fff;
    display: block;
  }
}
@media (max-width: 767px) {
  .fraud-alert__header {
    text-transform: uppercase;
  }
}
.fraud-alert__content {
  display: none;
  padding: var(--space-8);
  text-align: center;
  line-height: 1.35;
}
.fraud-alert__content > p {
  font-size: inherit;
  line-height: inherit;
}
@media (min-width: 768px) {
  .fraud-alert__content {
    background-color: var(--color-blue-200);
    color: var(--color-blue-1100);
    font-size: 0.75rem;
  }
}
@media (min-width: 1440px) {
  .fraud-alert__content {
    padding-inline: 255px;
  }
}
.fraud-alert__disclose {
  font-weight: 500;
  background-color: transparent;
  background-image: none;
  border: none;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: inherit;
}
.fraud-alert__disclose::after {
  font-family: HB Pictos !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f11c";
  font-size: 12px;
}
.fraud-alert__disclose[aria-expanded=true]::after {
  font-family: HB Pictos !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\f11f";
  font-size: 12px;
}